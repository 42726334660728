@use 'vars' as *;
body {
    font-family: $font-primary;
    font-size: 16px;
    color: $dark-grey;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}

img {
    height: auto !important;
}

.clear-float {
    clear: both;
}

img.display-left {
    float: left;
    max-width: 50%;
    margin-right: 20px;
    margin-bottom: 10px;
}

img.display-right {
    float: right;
    max-width: 50%;
    margin-left: 20px;
    margin-bottom: 10px;
}

.white-text {
    color: $white;
}

.blue-text {
    color: $blue;
}

.violet-text {
    color: $violet;
}

/** set colours for headings **/
h1, h2, h3 {
    color: $blue;
}
h4 {
    color: $violet;
}

.btn-gradient-outline {
    @include button-style;
    display: flex;
    align-items: center; /* vertical */
    justify-content: center; /* horizontal */
    position: relative;
    box-sizing: border-box;
    $border: 2px;
    color: $violet;
    background: $white;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: solid $border transparent;
    font-weight: bold;
    // width: auto;
    // height: auto;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: $site-gradient;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        //   filter: hue-rotate(0deg);
    }
    transition: all .5s ease;
    // -webkit-backface-visibility: hidden;
    // backface-visibility: hidden;
    &:hover {
        &:before {
            filter: hue-rotate(25deg);
        }
        color: white;
        background-color: transparent;
    }
}

.btn-gradient {
    @include button-style;
    width: auto;
    background: $site-gradient;
    transition: all .5s ease;
    outline: none;
    border: none;
    // filter: hue-rotate(0deg);
    filter: hue-rotate(0deg) brightness(100%);
    &:hover {
        filter: hue-rotate(45deg) brightness(120%);
        // box-shadow: 0 0 40px -5px $violet;
    }
}

.btn-blue {
    @include button-style;
    background: $blue;
    transition: all .5s ease;
    border: 2px solid $blue;
    &:hover {
        background: transparent;
        color: $blue;
    }
    text-shadow: none;
}

.btn-white-outline {
    @include button-style;
    border: 2px solid $white;
    background-color: transparent;
    transition: all .5s ease;
    &:hover {
        background-color: $violet;
        color: $white;
        border-color: transparent;
    }
}

@media only screen and (max-width: 760px) {
    img.display-left,
    img.display-right {
        float: none;
        display: block;
        max-width: 100%;
        margin: 20px auto;
    }
}