$pink:rgba(206,98,165,1);
$violet:#772E8F;
$blue:#21BEC6;
$white:#FFFFFF;
$grey:#999999;
$dirty-white:#bcbcbc;
$dark-grey:#4D4D4D;
$black:#000;
$dark-pink:darken($pink, 15%);
$dark-violet:darken($violet, 15%);
$dark-blue:darken($blue, 15%);
$indigo:#0F3959;
$site-gradient: linear-gradient(90deg, $pink -10%, $violet 10%, $blue 110%);
$site-gradient-light: linear-gradient(90deg, lighten($pink, 10%) -10%, lighten($violet, 10%) 60%, lighten($blue, 10%) 110%);
$site-gradient-dark: linear-gradient(90deg, $dark-pink -10%, $dark-violet 10%, $dark-blue 110%);
$site-gradient-reverse: linear-gradient(270deg, $pink -10%, $violet 10%, $blue 110%);
$site-gradient-dark-reverse: linear-gradient(270deg, $dark-pink -10%, $dark-violet 10%, $dark-blue 110%);
$font-primary: montserrat, sans-serif;

@mixin button-style {
    color: $white;
    border-radius: 3rem;
    border: none;
    padding: 10px 20px;
    width: fit-content;
    text-decoration: none;
    white-space: nowrap;
}
@mixin skew-element {
    transform: skewY(-30deg);
    & > *{
        transform: skewY(30deg);
    }
}